<template>
  <div>
    <!-- 交易流水 -->
    <en-table-layout tips :tableData="tableData.data" :loading="loading">
      <!-- 搜索条件 -->
      <div slot="tips" class="inner-toolbar">
        <div class="toolbar-btns">
          <!--交易类型-->
          <div class="conditions">
            <div style="display: flex; align-items: center;">
              <div>是否支持退款:</div>
              <el-select v-model="advancedForm.refund_type" clearable style="width: 55%">
                <el-option value="0" label="否" />
                <el-option value="1" label="是" />
              </el-select>
            </div>
            <div style="display: flex; align-items: center;">
               <div>结算状态:</div>
              <el-select v-model="advancedForm.bill_status" clearable style="width: 55%">
                <el-option value="0" label="未结算" />
                <el-option value="1" label="已结算" />
              </el-select>
            </div>
           <div style="display: flex; align-items: center;">
             <div>结算时间:</div>
              <el-date-picker
                :default-time="['00:00:00', '23:59:59']"
                value-format="timestamp"
                style="width: 78%;margin-left: 5px;"
                v-model="advancedForm.times"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
           </div>
          </div>
        </div>
        <!-- 关键字-->
        <div class="conditions" style="margin-top: 15px; width: 100%;">
          <div>选择关键字</div>
          <el-select v-model="keyword_type" style="width: 180px;">
            <el-option value="0" label="订单编号"></el-option>
            <el-option value="1" label="活动编号"></el-option>
            <el-option value="2" label="活动名称"></el-option>
          </el-select>
          <el-input
            class="ipt-default"
            style="width: 220px"
            v-model="kayValue"
            placeholder="请输入关键字进行搜索"
            clearable
          ></el-input>
          <el-button style="color: #ffffff; background-color: #008080" class="btn-default" @click="searchEvent('search')">筛选</el-button>
          <el-button style="color: #ffffff; background-color: #008080" class="btn-default" @click="searchEvent('export')">导出</el-button>
        </div>
        <!--绑定时间-->
        <div class="conditions conditionss">
          <!-- <el-date-picker
            v-model="advancedForm.times"
            value-format="timestamp"
            type="daterange"
            :default-time="['00:00:00', '23:59:59']"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="MixinPickerShortcuts"
          ></el-date-picker>-->
          <!-- :picker-options="{disabledDate(time) { return time.getTime() > Date.now() }}" -->
        </div>
      </div>
      <!-- 表格上部按钮 -->
      <!-- <div slot="toolbar">
        <el-tabs
          v-model="advancedForm.in_type"
          @tab-click="handleTabsChange"
          type="card"
          class="elTabBox"
        >
          <el-tab-pane label="全部" name="3"></el-tab-pane>
          <el-tab-pane label="收款" name="1"></el-tab-pane>
          <el-tab-pane label="付款" name="2"></el-tab-pane>
        </el-tabs>
      </div> -->
      <!-- 表格数据 -->
      <template slot="table-columns">
        <el-table-column prop="sn" label="订单编号" />
        <el-table-column prop="name" label="活动名称" />
        <el-table-column prop="sku_sn" label="活动编号" />
        <el-table-column prop="cat_first_name" label="活动一级分类" />
        <el-table-column prop="cat_second_name" label="活动二级分类" />
        <el-table-column label="退款类型">
          <template slot-scope="scope">
            <div v-if="scope.row.launch_status">
              {{ scope.row.launch_status===1?'用户':'商户'}}
            </div>
            </template>
        </el-table-column>
        <el-table-column label="是否支持退款">
          <template slot-scope="scope">{{ scope.row.refund_type===1?'是':'否'}}</template>
        </el-table-column>
        <el-table-column label="退款比例">
          <template slot-scope="scope">
            <div v-if="scope.row.refund_status === 'COMPLETED'">
              {{scope.row.point ? scope.row.point : 0}}
            </div>
            <div v-else>
              0
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="order_price" label="订单金额" />
        <el-table-column prop="refund_price" label="退款金额">
          <template slot-scope="scope">
            <div v-if="scope.row.refund_status === 'COMPLETED'">
              {{scope.row.refund_price ? scope.row.refund_price : 0}}
            </div>
            <div v-else>
              0
            </div>
          </template>
        </el-table-column>
        <el-table-column label="佣金支出" prop="platform_commission" />
        <el-table-column label="交易手续支出" prop="service_charge" />
        <el-table-column label="结算金额">
          <template slot-scope="scope">
            <div v-if="scope.row.refund_status === 'COMPLETED '">
              {{ scope.row.bill_money - scope.row.refund_price}}
            </div>
            <div v-else>
              {{scope.row.bill_money}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="结算状态">
          <template slot-scope="scope">{{ scope.row.bill_status===0?'未结算':'已结算'}}</template>
        </el-table-column>
        <el-table-column label="结算时间" width="180">
          <template slot-scope="scope" >
            <div v-if="scope.row.bill_time">
              {{ scope.row.bill_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}
            </div>
          </template>
        </el-table-column>
      </template>
      <!-- 表格分页 -->
      <el-pagination
        v-if="tableData"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import * as API_Account from "@/api/account";
// import { RegExp } from "@libs/common/utils";
import * as Export2Excel from "@/utils/exportXlsx";
// import { delete } from 'vue/types/umd';

export default {
  name: "transactionFlow",
  data() {
    return {
      // pickerOptions: {
      //   shortcuts: [
      //     {
      //       text: "今天",
      //       onClick(picker) {
      //         const end = new Date();
      //         const start = new Date();
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 0);
      //         picker.$emit("pick", [start, end]);
      //       },
      //     },
      //     {
      //       text: "近3日",
      //       onClick(picker) {
      //         const end = new Date();
      //         const start = new Date();
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
      //         picker.$emit("pick", [start, end]);
      //       },
      //     },
      //     {
      //       text: "近7日",
      //       onClick(picker) {
      //         const end = new Date();
      //         const start = new Date();
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      //         picker.$emit("pick", [start, end]);
      //       },
      //     },
      //     {
      //       text: "近30日",
      //       onClick(picker) {
      //         const end = new Date();
      //         const start = new Date();
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      //         picker.$emit("pick", [start, end]);
      //       },
      //     },
      //   ],
      // },
      /** 列表loading状态 */
      loading: false,

      // 地区id数组
      defaultRegion: null,

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 10,
      },
      /** 列表数据 */
      tableData: "",

      /** 查询条件 */
      keyword_type: '',
      kayValue: '',

      /** 高级搜索数据 */
      advancedForm: {
        refund_type: '',
        bill_status: '',
        sn: '',
        name: '',
        sku_sn: ''
      },
    };
  },

  mounted() {
    this.GET_MemberList();
  },
  beforeRouteUpdate(to, from, next) {
    this.GET_MemberList();
    next();
  },
  methods: {
    /** tab切换 */
    handleTabsChange(val, e) {
      this.searchEvent();
    },
    /** 查看 */
    handleScaleEdit(row) {
      this.$router.push({
        name: "交易流水详情",
        params: {
          id: row.trade_id,
        },
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_MemberList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_MemberList();
    },

    /** 搜索事件触发 */
    searchEvent(type) {
      this.advancedForm.sn = ''
      this.advancedForm.name = ''
      this.advancedForm.sku_sn = ''
      if (this.keyword_type === '0') {
        this.advancedForm.sn = this.kayValue
      } else if (this.keyword_type === '1') {
        this.advancedForm.sku_sn = this.kayValue
      } else if (this.keyword_type === '2') {
        this.advancedForm.name = this.kayValue
      }
      // Object.keys(this.advancedForm).forEach(key => delete this.params[key]);
      this.params.page_no = 1;
      this.params = {
        ...this.params,
        page_no: 1,
        ...this.advancedForm,
      };
      delete this.params.start_date;
      delete this.params.end_date;
      if (this.params.in_type === 0) delete this.params.in_type;
      if (this.advancedForm.times) {
        this.params.star_time = this.advancedForm.times[0] / 1000;
        this.params.end_time = this.advancedForm.times[1] / 1000;
      }
      delete this.params.times;
      if (type === 'search') {
        this.GET_MemberList();
      } else {
        console.log(111)
        this.loading = true;
        delete this.params.page_no
        delete this.params.page_size
        const { params } = this;
        Object.keys(params).forEach((key) => {
          if (!params[key] && params[key] !== 0) {
            delete params[key];
          }
        });
        API_Account.exportBillData(params).then(res => {
          var excelData = [];
          res.forEach(item => {
            var excelDataRow = [];
            excelDataRow.push(item.sn)
            excelDataRow.push(item.name)
            excelDataRow.push(item.sku_sn)
            excelDataRow.push(item.cat_first_name)
            excelDataRow.push(item.cat_second_name)
            excelDataRow.push(item.launch_status === 1 ? '用户' : '商户')
            excelDataRow.push(item.refund_type === 0 ? '不支持' : '支持')
            excelDataRow.push(item.point)
            excelDataRow.push(item.order_price)
            excelDataRow.push(item.refund_price)
            excelDataRow.push(item.platform_commission)
            excelDataRow.push(item.service_charge)
            excelDataRow.push(item.bill_money)
            excelDataRow.push(item.bill_status === 0 ? '未结算' : '已结算')
            excelDataRow.push(item.bill_time ? this.MixinUnixToDate("", "", item.bill_time) : '')
            excelData.push(excelDataRow);
          })
          Export2Excel.export_json_to_excel([
            "订单编号",
            "活动名称",
            "活动编号",
            "活动一级分类",
            "活动二级分类",
            "退款类型",
            "是否支持退款",
            "退款比例",
            "订单金额",
            "退款金额",
            "佣金支出",
            "交易手续费支出",
            "结算金额",
            "结算状态",
            "结算到账户余额的时间"
          ],
          excelData,
          "结算明细数据"
          )
        })
        this.loading = false
      }
    },
    /** 获取列表 */
    GET_MemberList() {
      this.loading = true;
      const { params } = this;
      Object.keys(params).forEach((key) => {
        if (!params[key] && params[key] !== 0) {
          delete params[key];
        }
      });
      API_Account.getBillData(params)
        .then((response) => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.elTabBox {
  /deep/ .el-tabs__item {
    background: #fff;
  }

  /deep/ .is-active {
    background: #008080;
    color: #fff;
  }

  /deep/ #tab-first {
    border-radius: 4px 0 0 4px;
  }

  /deep/ #tab-second {
    border-radius: 0 4px 4px 0;
  }
}

.el-date-editor.el-input {
  width: 180px;
}

/deep/ .form-item-sex .el-form-item__content {
  width: 180px;
}

/deep/ .form-item-region .el-form-item__content {
  min-width: 180px;
}
/deep/ .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 30px;
}
.inner-toolbar {
  flex-wrap: wrap;
}
div.toolbar-btns {
  display: flex;
  justify-content: inherit;
  width: 100%;
}

div.toolbar-btns .conditions {
  margin-right: 0px;
}
.conditionss {
  margin-top: 5px;
}
</style>
